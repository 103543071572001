import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/beht_logo.png'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-primary has-text-white-ter">
        <div className="content has-text-centered">
          <Link to="/">
            <img className="logo-footer" src={logo} alt="BEHT logo"/>
          </Link>
          <div className="is-size-7 has-text-white-ter">Registered Charity No. 1072109</div>
        </div>
        <div className="has-text-centered mt-3">
          <div className="container">
            <div className="columns is-multiline is-mobile">
              <div className="column is-half-mobile">
                <ul>
                  <li className="has-text-weight-semibold"><Link to="/">Home</Link></li>
                  <br />
                  <br />
                  <li className="has-text-weight-semibold"><Link to="/news">News</Link></li>
                  <br />
                  <br />
                  <li className="has-text-weight-semibold"><Link to="/contact">Contact Us</Link></li>
                </ul>
              </div>
              <div className="column is-half-mobile">
                <ul>
                  <li className="has-text-weight-semibold"><Link to="/about-us">About Us</Link></li>
                  <li><Link to="/about-us/our-story">Our Story</Link></li>
                  <li><Link to="/about-us/our-inspiration">Our Inspiration</Link></li>
                  <li><Link to="/about-us/achievements">Achievements</Link></li>
                  <li><Link to="/about-us/our-team">Our Team</Link></li>
                  <li><Link to="/about-us/accountability">Accountability</Link></li>
                </ul>
              </div>
              <div className="column is-half-mobile">
                <ul>
                  <li className="has-text-weight-semibold"><Link to="/projects">Our Projects</Link></li>
                  <li><Link to="/projects/education">Education</Link></li>
                  <li><Link to="/projects/health">Health</Link></li>
                  <li><Link to="/projects/elderly">Elderly</Link></li>
                  <li><Link to="/projects/animals-and-environment">Animals and Environment</Link></li>
                  <li><Link to="/projects/relief">Relief Work</Link></li>
                </ul>
              </div>
              <div className="column is-half-mobile">
                <ul>
                  <li className="has-text-weight-semibold"><Link to="/get-involved">Get Involved</Link></li>
                  <li><Link to="/get-involved/donate">Donate</Link></li>
                  <li><Link to="/get-involved/events">Events</Link></li>
                  <li><Link to="/get-involved/volunteer">Volunteer</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
